import Breadcrumb from '../utilities/Breadcrumb'
import PageTitle from '../utilities/PageTitle'
import { Outlet } from 'react-router-dom'

const AutoCommentsHome = () => {
  const autocommentsHeader = {
    ToolName: 'Auto Comments',
    navTag: ['Config'],
  }

  return (
    <>
      <PageTitle />
      <Breadcrumb info={autocommentsHeader} />
      <Outlet />
    </>
  )
}

export default AutoCommentsHome