import styles from "./FeedPost.module.scss"
import { useState } from "react"
import noimage from "../../../images/noimage.jpg"
import { HiShare } from "react-icons/hi"
import { BiLink } from "react-icons/bi"
import ShareBox from "../../utilities/ShareBox"
import { getMediaUrl } from "../../../context/getMediaUrl"

const FeedPost = ({ feedData }) => {
  // console.log(feedData.reshares[0])
  const [shareBoxShow, setShareBoxShow] = useState(false)

  const openShareBoxHandler = () => {
    setShareBoxShow(true)
  }
  const closeShareBoxHandler = () => {
    setShareBoxShow(false)
  }
  const mediaUrl = getMediaUrl(feedData.media)
  // console.log(mediaUrl)

  return (
    <div className={styles.wrapper}>
      <div className={styles.img_wrapper}>
        <img
          src={mediaUrl || noimage}
          alt={mediaUrl ? "poster-img" : "no-image-available"}
        />
      </div>
      <div className={styles.post_content}>
        <div className={styles.main_content}>
          <div className={styles.info}>
            <div className={styles.shares}>
              <HiShare size={12} />
              <span>{feedData.reshares.length}</span>
            </div>
            <p className={styles.time}>
              Posted: <strong>{feedData.posted}</strong>
            </p>
          </div>
          <a
            href={feedData.post}
            className={styles.link}
            target="_blank"
            rel="noreferrer"
          >
            <h2>
              {feedData.title}
              <BiLink size={22} />
            </h2>
          </a>
          <div className={styles.keywords}>
            {feedData.tags.map((tag, index) => (
              <span key={index}>{tag.replace(/^tag:/, "")}</span>
            ))}
          </div>
        </div>
        <div className={styles.btn}>
          <button className="primary_outline_btn" onClick={openShareBoxHandler}>
            Condividi
          </button>
          {shareBoxShow && (
            <ShareBox
              onCloseShareBox={closeShareBoxHandler}
              currentPost={feedData}
              currentMediaUrl={mediaUrl}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default FeedPost
