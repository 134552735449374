import styles from "./AnalyticsHeader.module.scss"
import Dropdown from "../../utilities/Dropdown"

export const postTypeOpts = [
  { value: "all", label: "All" },
  { value: "text", label: "Text" },
  { value: "link", label: "Link" },
  { value: "image", label: "Image" },
  { value: "image_link", label: "Image Link" },
  { value: "video", label: "Video" },
  { value: "other", label: "Other" },
]
export const timeBackOpts = [
  { value: "", label: "None" },
  { value: "12h", label: "12 Hours" },
  { value: "24h", label: "24 Hours" },
  { value: "3d", label: "3 Days" },
  { value: "7d", label: "7 Days" },
  { value: "14d", label: "14 Days" },
  { value: "30d", label: "30 Days" },
]

const AnalyticsHeader = ({
  onTypeChange,
  postType,
  timeBack,
  onTimeBackChange,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.item_group}>
        <span>Post type</span>
        <Dropdown
          options={postTypeOpts}
          value={postType}
          onChangeOpt={onTypeChange}
        />
      </div>
      <div className={styles.item_group}>
        <span>Time back</span>
        <Dropdown
          options={timeBackOpts}
          value={timeBack}
          onChangeOpt={onTimeBackChange}
        />
      </div>
      {/* <span className={styles.count}>148 elementi</span> */}
    </div>
  )
}

export default AnalyticsHeader
