import ToggleBtnCube from "../../utilities/ToggleBtnCube"
import styles from "./NewFeedConfig.module.scss"
import { useState } from "react"

const NewFeedConfig = ({ onNewFeedClose, onNewFeedSubmit, displayNewFeed }) => {
  const emptyFeed = {
    name: "",
    url: "",
    status: true,
  }
  const [newFeed, setNewFeed] = useState(emptyFeed)

  const submitCommitHandler = (e) => {
    e.preventDefault()
    // console.log(newFeed)
    onNewFeedSubmit(newFeed)
    setNewFeed(emptyFeed)
  }

  return (
    <div className={`overlay ${displayNewFeed ? styles.show : null}`}>
      <div
        className={
          displayNewFeed
            ? [styles.wrapper, styles.wrapper_active].join(" ")
            : styles.wrapper
        }
      >
        {/* <div className={styles.logo}>
          <Logo />
        </div> */}
        <form className={styles.create_comment} onSubmit={submitCommitHandler}>
          <h2>Create a new Feed</h2>
          <div className={styles.inputGroup}>
            <label htmlFor="new_feed_name">Name</label>
            <input
              type="text"
              name=""
              id="new_feed_name"
              value={newFeed.name}
              onChange={(e) =>
                setNewFeed((prev) => ({ ...prev, name: e.target.value }))
              }
              required
            />
          </div>
          <div className={styles.inputGroup}>
            <label htmlFor="new_feed_url">URL</label>
            <input
              type="url"
              id="new_feed_url"
              cols="30"
              rows="5"
              value={newFeed.url}
              onChange={(e) =>
                setNewFeed((prev) => ({ ...prev, url: e.target.value }))
              }
              required
            ></input>
          </div>
          <div className={styles.inputGroup}>
            <ToggleBtnCube
              caption={"Enabled"}
              status={newFeed.status}
              onStatusToggle={() =>
                setNewFeed((pre) => ({ ...pre, status: !pre.status }))
              }
            />
          </div>
          <div className={styles.btnGroup}>
            <button className="primary_btn" type="submit">
              Create
            </button>
            <button
              type="button"
              className="primary_outline_btn"
              onClick={onNewFeedClose}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default NewFeedConfig
