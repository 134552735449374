import { useId } from "react"
import styles from "./ToggleBtnCube.module.scss"

const ToggleBtnCube = ({ caption, status, onStatusToggle }) => {
  const id = useId()

  const toggleSwitchHandler = () => {
    onStatusToggle(!status)
  }

  return (
    <div className={styles.switch_box}>
      <span className={status ? styles.activated : null}>{caption}</span>
      <label htmlFor={id} className={styles.switch}>
        <input
          type="checkbox"
          id={id}
          onChange={toggleSwitchHandler}
          className={status ? styles.checked : null}
        />
        <span className={styles.slider}></span>
      </label>
    </div>
  )
}

export default ToggleBtnCube
