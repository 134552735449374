import styles from "./HomePage.module.scss"
import { BsArrowUpSquare } from "react-icons/bs"

const LoggedInPage = () => {
  return (
    <div className="container">
      <div className={styles.msg}>
        <BsArrowUpSquare />
        <h2>Select the page from nav.</h2>
      </div>
    </div>
  )
}

export default LoggedInPage
