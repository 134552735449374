// import NewConfigComment from "../../utilities/NewConfigComment"
import { useState } from "react"
import ConfigPost from "./ConfigPost"
import { MdPlaylistAdd } from "react-icons/md"
import NewFeedConfig from "./NewFeedConfig"

const ConfigList = ({
  feedData,
  onFeedStatusToggle,
  onFeedCreate,
  onFeedDelete,
}) => {
  const [showNewFeed, setShowNewFeed] = useState(false)
  const openNewFeedHandler = () => {
    setShowNewFeed(true)
  }

  const closeNewFeedHandler = (e) => {
    setShowNewFeed(false)
  }

  const submitNewFeedHandler = (feed) => {
    setShowNewFeed(false)
    onFeedCreate(feed)
  }

  return (
    <div>
      <table>
        <thead className="table_header">
          <tr>
            <th>Feed</th>
            <th>Status</th>
            <th>
              <button
                onClick={openNewFeedHandler}
                className="primary_btn narrow new"
              >
                <span>New</span>
                <MdPlaylistAdd size={22} />
              </button>
              <NewFeedConfig
                onNewFeedClose={closeNewFeedHandler}
                onNewFeedSubmit={submitNewFeedHandler}
                displayNewFeed={showNewFeed}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {feedData.length === 0 ? (
            <tr>
              <td colSpan={3} className="no_data_msg">
                There are no feeds set up yet.
              </td>
            </tr>
          ) : (
            feedData.map((feed, index) => (
              <ConfigPost
                key={index}
                post={feed.settings}
                onFeedStatusToggle={(status) =>
                  onFeedStatusToggle(index, status)
                }
                onFeedDelete={() => onFeedDelete(index)}
              />
            ))
          )}
        </tbody>
      </table>
    </div>
  )
}

export default ConfigList
