import { createContext, useState } from "react"

const PageContext = createContext({})

export const PageProvider = ({ children }) => {
  const [selectedPage, setSelectedPage] = useState({})
  const [allPages, setAllPages] = useState([])

  return (
    <PageContext.Provider
      value={{ selectedPage, setSelectedPage, allPages, setAllPages }}
    >
      {children}
    </PageContext.Provider>
  )
}

export default PageContext
