import styles from "./QueueChildPost.module.scss"
import { LuExternalLink } from "react-icons/lu"

const QueueChildPost = ({
  displayChildTable,
  originalPostContent,
  originalPostLink,
}) => {
  return (
    <tr className={`table_content ${styles.child_row}`}>
      <td
        colSpan={3}
        className={`${styles.child_cell} ${!displayChildTable && styles.hide}`}
      >
        <div className={`${styles.child_content}`}>
          <a href={originalPostLink} target="_blank" rel="noreferrer">
            <h2>
              Original content <LuExternalLink />
            </h2>
          </a>
          {originalPostContent}
        </div>
      </td>
    </tr>
  )
}

export default QueueChildPost
