import { useEffect, useState } from "react"
import { api } from "../../../lib/axios"
import usePage from "../../../context/usePage"
import LoadingSpinner from "../../utilities/LoadingSpinner"
import SchedulerList from "./SchedulerList"

const Stats = () => {
  const [data, setData] = useState([])
  const token = JSON.parse(localStorage.getItem("loggedInfo")).sessionToken

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/feeder/stats/", {
          headers: {
            "Session-Token": token,
          },
        })
        const responseData = response.data
        setData(responseData)
      } catch (error) {
        console.log(error)
      }
    }
    fetchData()
  }, [token])

  return (
    <div style={{ maxHeight: 400, overflow: "auto" }}>
      <table>
        <thead className="table_header">
          <tr>
            <th>Page</th>
            <th>Published</th>
            <th>Scheduled</th>
          </tr>
          <tr>
            <th style={{ textAlign: "right" }}>Total</th>
            <th style={{ textAlign: "right" }}>
              {data.reduce(
                (prev, current) => prev + current.published_posts,
                0,
              )}
            </th>
            <th style={{ textAlign: "right" }}>
              {data.reduce(
                (prev, current) => prev + current.scheduled_posts,
                0,
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {data
            .filter((row) => row.published_posts + row.scheduled_posts > 0)
            .map((row) => (
              <tr key={row.page_reference_id} className="table_content">
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 10,
                      alignItems: "center",
                    }}
                  >
                    <img
                      width={32}
                      height={32}
                      src={`https://graph.facebook.com/${row.page_reference_id}/picture?type=square`}
                    />
                    {row.page_name}
                  </div>
                </td>
                <td
                  style={{
                    textAlign: "right",
                    padding: "0 10px",
                    verticalAlign: "middle",
                  }}
                >
                  {row.published_posts}
                </td>
                <td
                  style={{
                    textAlign: "right",
                    padding: "0 10px",
                    verticalAlign: "middle",
                  }}
                >
                  {row.scheduled_posts}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}

const SchedulerPage = () => {
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const token = JSON.parse(localStorage.getItem("loggedInfo")).sessionToken
  const { selectedPage } = usePage()

  useEffect(() => {
    const fetchShedulerData = async () => {
      setIsLoading(true)
      try {
        const response = await api.get("/feeder/rewritten-posts/", {
          params: {
            status: "approved",
          },
          headers: {
            "Session-Token": token,
          },
        })
        const responseData = response.data.results
        setData(responseData)
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false)
      }
    }
    fetchShedulerData()
  }, [selectedPage, token])

  const save = async (change) => {
    const { postid, action } = change
    try {
      const response = await api.patch(
        `/feeder/rewritten-posts/${postid}/`,
        {
          status: action,
        },
        {
          headers: {
            "Session-Token": token,
          },
        },
      )
      if (response.status === 200) {
        setData((pre) => pre.filter((item) => item.id !== postid))
      }
    } catch (error) {
      console.log(error)
    }
  }

  const postActionHandler = (postid, action) => {
    save({
      postid,
      action,
    })
  }

  return (
    <section className="container">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Stats />
          <SchedulerList posts={data} onPostAction={postActionHandler} />
        </>
      )}
    </section>
  )
}

export default SchedulerPage
