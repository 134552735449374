import styles from "./Header.module.scss"
import { useState } from "react"
import { Link } from "react-router-dom"
import { HiOutlineMenuAlt2 } from "react-icons/hi"
import { RxExit } from "react-icons/rx"
import Logo from "../utilities/Logo"
import NavList from "./NavList"
import useAuth from "../../context/useAuth"
import usePage from "../../context/usePage"

const Header = () => {
  const { globalAuth } = useAuth()
  const { setSelectedPage } = usePage()
  const [isNavOpened, setIsNavOpened] = useState(false)

  const openNavHandler = () => {
    setIsNavOpened(true)
  }
  const closeNavHandler = (page) => {
    if (page) {
      setSelectedPage(page)
    }
    setIsNavOpened(false)
  }

  const logOutHandler = () => {
    localStorage.removeItem("loggedInfo")
  }

  return (
    <header className={styles.wrapper}>
      <NavList status={isNavOpened} onNavClose={closeNavHandler} />
      <div
        className={`primary_outline_btn ${styles.hamburger}`}
        onClick={openNavHandler}
      >
        <HiOutlineMenuAlt2 size={20} />
      </div>
      <div className={styles.logo}>
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <div className={styles.username}>
        <span>{globalAuth.formattedUsername}</span>
      </div>
      <div className="primary_outline_btn">
        <Link to="/login" className={styles.logout} onClick={logOutHandler}>
          <p>Exit</p>
          <RxExit size={18} />
        </Link>
      </div>
    </header>
  )
}

export default Header
