import styles from "./Breadcrumb.module.scss"
import { NavLink } from "react-router-dom"
import { TfiAngleRight } from "react-icons/tfi"

const Breadcrumb = ({ info }) => {
  return (
    <div className="container">
      <div className={styles.wrapper}>
        <h3>{info.ToolName}</h3>
        <TfiAngleRight size={12} />
        {info.navTag.map((tag) => (
          <NavLink
            to={tag.toLowerCase().replace(" ", "-")}
            key={tag}
            className={({ isActive }) => (isActive ? styles.active : undefined)}
          >
            {tag}
          </NavLink>
        ))}
      </div>
    </div>
  )
}

export default Breadcrumb
