import styles from "./FeedHeader.module.scss"

const FeedHeader = ({
  onArticleSharesChange,
  articleShares,
  sortPostedtime,
}) => {
  return (
    <div className={styles.wrapper}>
      <div>Post</div>
      <div className={styles.option_group}>
        <span>Sorting</span>
        <select
          value={sortPostedtime}
          onChange={(event) => onArticleSharesChange(event.target.value)}
        >
          <option value="pd">Newer first</option>
          <option value="pa">Older first</option>
        </select>
      </div>

      <div className={styles.option_group}>
        <span>Article Shares</span>
        <select
          value={articleShares}
          onChange={(event) => onArticleSharesChange(event.target.value)}
        >
          <option value="">All</option>
          <option value="sn">None</option>
          <option value="so">Once</option>
          <option value="sm">Many</option>
        </select>
      </div>
    </div>
  )
}

export default FeedHeader
