import ConfigList from "./ConfigList"
import ConfigHeader from "./ConfigHeader"
import { useEffect, useState } from "react"
import { acb } from "../../lib/axios"
import usePage from "../../context/usePage"
import LoadingSpinner from "../utilities/LoadingSpinner"

const ConfigPage = () => {
  const [configData, setConfigData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const token = JSON.parse(localStorage.getItem("loggedInfo")).sessionToken
  const { selectedPage } = usePage()

  const pageConfigIndex = configData.findIndex(
    (entry) => entry.page_id === selectedPage.referenceId,
  )
  const pageConfigData =
    pageConfigIndex !== -1 ? configData[pageConfigIndex] : null

  useEffect(() => {
    const fetchConfigData = async () => {
      setIsLoading(true)
      try {
        const response = await acb.get("firstcomment/config", {
          headers: {
            "Session-Token": token,
          },
        })
        const responseData = response.data.data.first_comment_config.entries
        setConfigData(responseData)
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false)
      }
    }
    fetchConfigData()
  }, [selectedPage, token])

  const save = async (change) => {
    const newEntries = [...configData]

    if (pageConfigIndex === -1) {
      newEntries.push({
        comments: [],
        enabled: false,
        page_id: selectedPage.referenceId,
        post_type: "all",
        ...change,
      })
    } else {
      newEntries[pageConfigIndex] = {
        ...pageConfigData,
        ...change,
      }
    }

    try {
      const response = await acb.post(
        "firstcomment/config",
        {
          first_comment_config: {
            entries: newEntries,
          },
        },
        {
          headers: {
            "Session-Token": token,
          },
        },
      )
      if (response.status === 200) {
        setConfigData(newEntries)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const toggleEnableHandler = (status) => {
    save({ enabled: status })
  }
  const changeTypeHandler = (status) => {
    save({ post_type: status })
  }
  const createCommentHandler = (newComment) => {
    save({ comments: [...(pageConfigData?.comments || []), newComment] })
  }
  const deleteCommentHandler = (code) => {
    save({
      comments: pageConfigData.comments.filter(
        (comment) => comment.code !== code,
      ),
    })
  }
  return (
    <section className="container">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <ConfigHeader
            configData={pageConfigData}
            onEnableToggle={toggleEnableHandler}
            onTypeChange={changeTypeHandler}
          />
          <ConfigList
            comments={pageConfigData?.comments || []}
            onCommentCreate={createCommentHandler}
            onCommentDelete={deleteCommentHandler}
          />
        </>
      )}
    </section>
  )
}

export default ConfigPage
