import styles from "../login/HomePage.module.scss"
import { acb } from "../../lib/axios"

const FacebookConnect = () => {
  const hashParams = new URLSearchParams(window.location.hash.substring(1))
  const accessToken = hashParams.get("access_token")
  const token = JSON.parse(localStorage.getItem("loggedInfo")).sessionToken

  const setUserToken = async () => {
    const response = await acb.post(
      "user/token/add/end",
      {
        user_token: accessToken,
      },
      {
        headers: {
          "Session-Token": token,
        },
      }
    )

    if (response.status === 200) {
      window.location.href = "/"
    }
  }

  setUserToken()

  return (
    <div className="container">
      <div className={styles.msg}>
        <h2>Aggiornamento pagine in corso...</h2>
      </div>
    </div>
  )
}

export default FacebookConnect
