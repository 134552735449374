import { createContext, useState } from "react";

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  const [globalAuth, setGlobalAuth] = useState(JSON.parse(localStorage.getItem('loggedInfo')) || {})

  return (
    <AuthContext.Provider value={{ globalAuth, setGlobalAuth }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext