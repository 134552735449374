import { Navigate, Outlet, useLocation } from "react-router-dom"

const PrivateRoute = () => {
  const logInInfo = JSON.parse(localStorage.getItem('loggedInfo')) || []
  const location = useLocation()

  return (
    logInInfo?.isLoggedIn
      ? <Outlet />
      : <Navigate to='/login' state={{ from: location }} replace />
  )
}

export default PrivateRoute