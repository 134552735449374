import styles from "./ConfigHeader.module.scss"
import ToggleBtnCube from "../../utilities/ToggleBtnCube"

export const UpdatePeriodOpts = [
  { value: "30", label: "30" },
  { value: "60", label: "60" },
  { value: "90", label: "90" },
]

const ConfigHeader = ({ configData, onEnableToggle }) => {
  const changeEnableHandler = (status) => {
    onEnableToggle(status)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper}>
        <ToggleBtnCube
          caption={"Enabled"}
          onStatusToggle={changeEnableHandler}
          status={configData?.enabled || false}
        />
      </div>
    </div>
  )
}

export default ConfigHeader
