import { Outlet } from "react-router-dom"
import Breadcrumb from "../utilities/Breadcrumb"
import PageTitle from "../utilities/PageTitle"

const EchoboxHome = () => {
  const echoboxHeader = {
    ToolName: "EchoBox",
    navTag: ["Feed", "Analytics", "Config"],
  }

  return (
    <>
      <PageTitle />
      <Breadcrumb info={echoboxHeader} />
      <Outlet />
    </>
  )
}

export default EchoboxHome
