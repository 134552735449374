export const getMediaUrl = (url) => {
  if (!url) {
    return null
  }

  const youtubeId = url.match(/youtube\.com\/embed\/([a-zA-Z0-9-_]+)/)

  if (youtubeId) {
    return `https://img.youtube.com/vi/${youtubeId[1]}/mqdefault.jpg`
  }

  return url
}
