import { MdDeleteForever } from "react-icons/md"
import { confirmAlert } from "react-confirm-alert" // Import
import "react-confirm-alert/src/react-confirm-alert.css" // Import css

const ConfigPost = ({ comment, onCommentDelete }) => {
  const removeCommentHandler = () => {
    confirmAlert({
      title: "Are you sure you want to delete this AWESOME comment?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            onCommentDelete(comment.code)
          },
        },
        {
          label: "No",
        },
      ],
    })
  }
  return (
    <tr className="table_content">
      <td>
        <p>{comment.code}</p>
      </td>
      <td>
        <p>{comment.text}</p>
      </td>
      <td>
        <button
          className="primary_outline_btn delete"
          onClick={() => removeCommentHandler(comment.code)}
        >
          <span>Remove</span>
          <MdDeleteForever size={20} />
        </button>
      </td>
    </tr>
  )
}

export default ConfigPost
