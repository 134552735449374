import axios from "axios"

export const acb = axios.create({
  baseURL: "https://acbtools.it/papi/",
})

export const api = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? "https://api.growerizer.prettytomato.co.uk/"
      : "http://192.168.1.134:8000/",
})
