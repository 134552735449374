import styles from "./ConfigHeader.module.scss"
import ToggleBtnCube from "../utilities/ToggleBtnCube"
import Dropdown from "../utilities/Dropdown"

export const postTypeOpts = [
  { value: "all", label: "All" },
  { value: "photo", label: "Photo" },
  { value: "link", label: "Link" },
]

const ConfigHeader = ({ configData, onEnableToggle, onTypeChange }) => {
  const changeEnabledHandler = (status) => {
    onEnableToggle(status)
  }

  const changePostType = (opt) => {
    onTypeChange(opt)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.update_box}>
        <span>Post type</span>
        <Dropdown
          options={postTypeOpts}
          onChangeOpt={changePostType}
          value={configData?.post_type}
        />
      </div>
      <ToggleBtnCube
        caption={"Enabled"}
        onStatusToggle={changeEnabledHandler}
        status={configData?.enabled || false}
      />
    </div>
  )
}

export default ConfigHeader
