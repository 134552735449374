import styles from "./SchedulerPost.module.scss"
import { useState } from "react"
import { IoArrowUndoOutline } from "react-icons/io5"
import { FaRegCalendarCheck, FaRegCalendarTimes } from "react-icons/fa"

import noimage from "../../../images/noimage.jpg"

const SchedulerPost = ({ post, onPostAction }) => {
  const selectedImgIds = post.images
    .filter((image) => image.is_choosen)
    .map((image) => image.id)
  const choosenImage = selectedImgIds.length
    ? post.images.find((img) => selectedImgIds.includes(img.id))
    : null

  const defaultImageUrl = choosenImage ? choosenImage.preview : noimage
  const postContent = post.content
  const [isShowMore, setShowMore] = useState(false)
  const isMessageTooLong = postContent.length > 150

  return (
    <>
      <tr className={`table_content ${styles.wrapper} ${styles[post.status]}`}>
        <td>
          <div className={styles.post_content}>
            <div className={`${styles.status} ${styles[post.status]}`}>
              {post.status === "approved" && <FaRegCalendarCheck size={22} />}
              {post.status === "rejected" && <FaRegCalendarTimes size={22} />}
            </div>
            <div className={styles.img_wrapper}>
              <img src={defaultImageUrl} alt="post-img" />
              {selectedImgIds.length > 1 && (
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    padding: 10,
                    background: "rgba(0, 0, 0, 0.3)",
                    fontWeight: "bold",
                    color: "#fff",
                    borderTopLeftRadius: 9,
                  }}
                >
                  +{selectedImgIds.length - 1}
                </div>
              )}
            </div>
            <div className={styles.main_content}>
              <p>
                {isShowMore || !isMessageTooLong
                  ? postContent
                  : postContent.slice(0, 150) + "… "}
              </p>
              {isMessageTooLong && (
                <button
                  className="show_more"
                  onClick={() => setShowMore(!isShowMore)}
                >
                  {isShowMore ? "Show less" : "Show more"}
                </button>
              )}
            </div>
          </div>
        </td>
        <td>
          <div className={styles.action_btns}>
            <button
              value="pending"
              className={`primary_btn`}
              onClick={(e) => onPostAction(e.target.value)}
            >
              <IoArrowUndoOutline size={20} />
            </button>
          </div>
        </td>
      </tr>
    </>
  )
}

export default SchedulerPost
