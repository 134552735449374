import { MdDeleteForever } from "react-icons/md"
import { confirmAlert } from "react-confirm-alert" // Import
import "react-confirm-alert/src/react-confirm-alert.css" // Import css
import ToggleBtnCube from "../../utilities/ToggleBtnCube"

const ConfigPost = ({ post, onFeedStatusToggle, onFeedDelete }) => {
  const changeFeedStatusHandler = (status) => {
    onFeedStatusToggle(status)
  }

  const removeCommentHandler = () => {
    confirmAlert({
      title: "Are you sure you want to delete this AWESOME feed?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            onFeedDelete(post)
          },
        },
        {
          label: "No",
        },
      ],
    })
  }
  return (
    <tr className="table_content">
      <td>
        <h2>{post?.name}</h2>
        <a href={post?.url} target="_blank" rel="noreferrer">
          {post?.url}
        </a>
      </td>
      <td className="config_toggle_btn">
        <ToggleBtnCube
          status={post?.enabled}
          onStatusToggle={changeFeedStatusHandler}
        />
      </td>
      <td>
        <button
          className="primary_outline_btn delete"
          onClick={() => removeCommentHandler(post)}
        >
          <span>Remove</span>
          <MdDeleteForever size={20} />
        </button>
      </td>
    </tr>
  )
}

export default ConfigPost
