import styles from "./Pagination.module.scss"

const Pagination = ({ currentPage, onPageChange, noData }) => {
  return (
    <div className={styles.wrapper}>
      <button
        className="primary_outline_btn"
        disabled={currentPage === 0}
        onClick={() => onPageChange(currentPage - 1)}
      >
        Prev
      </button>
      <div>{currentPage + 1}</div>
      <button
        className="primary_outline_btn"
        disabled={noData}
        onClick={() => onPageChange(currentPage + 1)}
      >
        Next
      </button>
    </div>
  )
}

export default Pagination
