import styles from "./PageTitle.module.scss"
import usePage from "../../context/usePage"

const PageTitle = () => {
  const { selectedPage } = usePage()

  return (
    <div className="container">
      <div className={styles.wrapper}>
        <img src={selectedPage.logo} alt={selectedPage.name} />
        <h1>{selectedPage.name}</h1>
      </div>
    </div>
  )
}

export default PageTitle
