import styles from "./TrashBinList.module.scss"
import TrashBinPost from "./TrashBinPost"

const TrashBinList = ({ posts, onPostAction }) => {
  return (
    <table>
      <thead className="table_header">
        <tr>
          <th>Content</th>
          <th>
            Undo
            <span className={styles.note}>(pending)</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {posts.length === 0 ? (
          <tr>
            <td colSpan={3} className="no_data_msg">
              There are no posts set up yet.
            </td>
          </tr>
        ) : (
          posts.map((post) => (
            <TrashBinPost
              key={post.id}
              post={post}
              onPostAction={(action) => onPostAction(post.id, action)}
            />
          ))
        )}
      </tbody>
    </table>
  )
}

export default TrashBinList
