import Dropdown from "../../utilities/Dropdown"
import styles from "./NewConfigComment.module.scss"
import { useEffect, useState } from "react"

const NewConfigComment = ({
  parentPageOptions,
  onNewParentPageClose,
  onNewParentPageSubmit,
  displayNewComment,
}) => {
  const defaultNewPage = {
    parentPageId: "",
    daily_posts: "",
    delay_minutes: "",
  }
  const [newParentPage, setNewParentPage] = useState(defaultNewPage)

  useEffect(() => {
    if (parentPageOptions.length > 0) {
      const defaultParentPageId = parentPageOptions[0].value || ""
      setNewParentPage({
        parentPageId: defaultParentPageId,
        daily_posts: "",
        delay_minutes: "",
      })
    }
  }, [parentPageOptions])

  const submitCommitHandler = (e) => {
    e.preventDefault()
    onNewParentPageSubmit(newParentPage)
    setNewParentPage(defaultNewPage)
  }

  const onChangeParentPage = (newParentPageId) => {
    setNewParentPage((pre) => ({ ...pre, parentPageId: newParentPageId }))
  }

  const onChangeNewParentPage = (e) => {
    const { name, value } = e.target
    setNewParentPage((pre) => ({ ...pre, [name]: value }))
  }

  return (
    <div className={`overlay ${displayNewComment ? styles.show : null}`}>
      <div
        className={
          displayNewComment
            ? [styles.wrapper, styles.wrapper_active].join(" ")
            : styles.wrapper
        }
      >
        <form className={styles.create_comment} onSubmit={submitCommitHandler}>
          <h2>Add a new parent page</h2>
          <div className={styles.inputGroup}>
            <Dropdown
              options={parentPageOptions}
              onChangeOpt={onChangeParentPage}
              value={newParentPage.parentPageId}
            />
          </div>
          <div className={styles.input_group}>
            <label htmlFor="daily_posts">Daily posts</label>
            <input
              name="daily_posts"
              value={newParentPage.daily_posts}
              type="number"
              min={0}
              id="daily_posts"
              className="input_area"
              onChange={onChangeNewParentPage}
            />
          </div>
          <div className={styles.input_group}>
            <label htmlFor="delay_minutes">Delay minutes</label>
            <input
              name="delay_minutes"
              value={newParentPage.delay_minutes}
              type="number"
              min={0}
              id="delay_minutes"
              className="input_area"
              onChange={onChangeNewParentPage}
            />
          </div>
          <div className={styles.btnGroup}>
            <button className="primary_btn" type="submit">
              Create
            </button>
            <button
              type="button"
              className="primary_outline_btn"
              onClick={onNewParentPageClose}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default NewConfigComment
