import styles from "./ConfigList.module.scss"
import { MdPlaylistAdd } from "react-icons/md"
import NewConfigComment from "./NewConfigComment"
import ConfigPost from "./ConfigPost"
import { useState } from "react"
import usePage from "../../../context/usePage"

const ConfigList = ({
  configData,
  onNewParentPageSubmit,
  onParentPageDelete,
}) => {
  const [showNewChildModal, setShowNewParentModal] = useState(false)
  const { allPages, selectedPage } = usePage()

  const existingParentPageIds = configData
    .filter(
      (config) =>
        Number(config.parent_page_id) === Number(selectedPage.referenceId),
    )
    .map((config) => config.child_page_id)

  const parentPageOptions = allPages
    .filter(
      (page) =>
        page.referenceId !== selectedPage.referenceId &&
        !existingParentPageIds.includes(page.referenceId.toString()),
    )
    .map((page) => ({
      value: page.referenceId.toString(),
      label: page.name,
    }))

  const openNewCommentHandler = () => {
    setShowNewParentModal(true)
  }
  const closeNewParentPageHandler = (e) => {
    setShowNewParentModal(false)
  }

  return (
    <table className={styles.config}>
      <thead className="table_header">
        <tr>
          <th>Parent Page</th>
          <th>Daily posts</th>
          <th>Delay minutes</th>
          <th>
            <div>
              <button
                onClick={openNewCommentHandler}
                className={`primary_btn narrow new ${styles.header_button} `}
                disabled={parentPageOptions.length === 0}
              >
                <span>New</span>
                <MdPlaylistAdd size={22} />
              </button>
              <NewConfigComment
                parentPageOptions={parentPageOptions}
                onNewParentPageClose={closeNewParentPageHandler}
                onNewParentPageSubmit={(newParentPage) => {
                  onNewParentPageSubmit(newParentPage)
                  setShowNewParentModal(false)
                }}
                displayNewComment={showNewChildModal}
              />
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {configData.length === 0 ? (
          <tr>
            <td colSpan={3} className="no_data_msg">
              There are no posts set up yet.
            </td>
          </tr>
        ) : (
          configData.map((config, index) => (
            <ConfigPost
              key={`${configData.parent_page_id}_${index}`}
              config={config}
              onParentPageDelete={onParentPageDelete}
            />
          ))
        )}
      </tbody>
    </table>
  )
}

export default ConfigList
