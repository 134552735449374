import styles from "./Dropdown.module.scss"
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md"
import { useEffect, useRef, useState } from "react"

const Dropdown = ({ options, value, onChangeOpt }) => {
  const [menuOpened, setMenuOpened] = useState(false)
  const ref = useRef()
  const selectedOpt =
    options.find((option) => option.value === value) || options[0]

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (menuOpened && ref.current && !ref.current.contains(e.target)) {
        setMenuOpened(false)
      }
    }
    document.addEventListener("click", checkIfClickedOutside)
    return () => {
      document.removeEventListener("click", checkIfClickedOutside)
    }
  }, [menuOpened])

  const toggleMenuHandler = () => {
    setMenuOpened(!menuOpened)
  }

  const changeOpt = (value) => {
    setMenuOpened(false)
    onChangeOpt(value)
  }

  return (
    <div className={styles.dropdown_container} ref={ref}>
      <button
        type="button"
        id="dropdown_area"
        onClick={toggleMenuHandler}
        className={menuOpened ? styles.active : null}
      >
        {selectedOpt?.label}
      </button>
      <div className={styles.indicator_container}>
        {menuOpened ? (
          <MdKeyboardArrowUp size={22} rotate={180} />
        ) : (
          <MdKeyboardArrowDown size={22} />
        )}
      </div>
      <ul
        className={
          menuOpened ? [styles.menu, styles.open].join(" ") : styles.menu
        }
      >
        {options.map((opt) => (
          <li key={opt.value} onClick={() => changeOpt(opt.value)}>
            {opt.label}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Dropdown
