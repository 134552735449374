import styles from "./LoginPage.module.scss"
import { useEffect, useRef, useState, useCallback } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Logo from "../../images/growerizer.png"
import { acb } from "../../lib/axios"
import useAuth from "../../context/useAuth"

const Login = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const expired = searchParams.get("expired")

  const fromLocation = location.state?.from?.pathname || "/"

  const userRef = useRef()
  const [authInfo, setAuthInfo] = useState({})
  const { setGlobalAuth } = useAuth()
  const [userName, setUserName] = useState("")
  const [userPwd, setUserPwd] = useState("")
  const [errMsg, setErrMsg] = useState("")

  useEffect(() => {
    userRef.current.focus()
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const response = await acb.post("user/login", {
        email: userName,
        password: userPwd,
      })

      // const isError = new URL(response.request.responseURL).pathname === '/error'
      // if (isError) {
      //   setErrMsg('Incorrect Email or Password')
      //   console.log('run here')
      // } else {
      const sessionToken = response.data.data["session-token"]
      const showUserName = userName.split("@")[0]
      const formattedUsername =
        showUserName.charAt(0).toUpperCase() + showUserName.slice(1)
      setAuthInfo({
        userName,
        sessionToken,
        isLoggedIn: true,
        formattedUsername,
      })
      setUserName("")
      setUserPwd("")
      setErrMsg("")

      // }
    } catch (err) {
      if (err.response?.status === 400) {
        setErrMsg("Incorrect Email or Password")
      } else {
        setErrMsg(err.message)
      }
    }
  }

  const navigateCallback = useCallback(
    (path) => {
      navigate(path, { replace: true })
    },
    [navigate]
  )

  useEffect(() => {
    if (authInfo.isLoggedIn) {
      localStorage.setItem("loggedInfo", JSON.stringify(authInfo))
      navigateCallback("/")
      setGlobalAuth(authInfo)
    }
  }, [authInfo, navigateCallback, fromLocation, setGlobalAuth])

  useEffect(() => {
    if (expired === "true") {
      setErrMsg("Session expired, please re-log in.")
    }
  }, [expired])
  // useEffect(() => {
  //   if (authInfo.isLoggedIn) {
  //     localStorage.setItem('loggedInfo', JSON.stringify(authInfo))
  //     navigate(fromLocation, { replace: true });
  //   }
  // }, [authInfo, navigate])

  return (
    <section className={styles.background}>
      <div className={styles.container}>
        <div>
          <img src={Logo} alt="Logo" />
        </div>
        <div className={styles.details}>
          <form onSubmit={handleSubmit}>
            <div className={styles.input_group}>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                placeholder="abc@gamil.com"
                className="input_area"
                ref={userRef}
                onChange={(e) => setUserName(e.target.value)}
                value={userName}
                required
              />
            </div>
            <div className={styles.input_group}>
              <label htmlFor="password">Password</label>
              <input
                type="password"
                placeholder="password"
                className="input_area"
                onChange={(e) => setUserPwd(e.target.value)}
                value={userPwd}
                required
              />
            </div>
            {errMsg && (
              <div className={`${styles.errMsg} ${errMsg && styles.show}`}>
                {errMsg}
              </div>
            )}
            <div className={styles.input_group}>
              {/* <button type="button" onClick={navigateHandler} className={`primary_btn`}>Accedi</button> */}
              <button className={`primary_btn ${styles.logInBtn}`}>
                Accedi
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default Login
