import { useEffect, useState } from "react"
import ConfigHeader from "./ConfigHeader"
import ConfigList from "./ConfigList"
import usePage from "../../../context/usePage"
import { api } from "../../../lib/axios"
import LoadingSpinner from "../../utilities/LoadingSpinner"

const ConfigPage = () => {
  const [configData, setConfigData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const token = JSON.parse(localStorage.getItem("loggedInfo")).sessionToken
  const { selectedPage } = usePage()

  const [status, setStatus] = useState(false) //TODO: with API

  useEffect(() => {
    const fetchConfigData = async () => {
      setIsLoading(true)
      try {
        const response = await api.get(`/feeder/relationships/`, {
          params: {
            child_page_id: selectedPage.referenceId,
          },
          headers: {
            "Session-Token": token,
          },
        })
        const responseData = response.data.results
        setConfigData(responseData)
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false)
      }
    }
    fetchConfigData()
  }, [selectedPage, token])

  const save = async (change) => {
    const { newParentPage } = change
    try {
      const response = await api.post(
        `/feeder/relationships/`,
        {
          parent_page_id: newParentPage.parentPageId,
          child_page_id: selectedPage.referenceId,
          daily_posts: Number(newParentPage.daily_posts),
          delay_minutes: Number(newParentPage.delay_minutes),
        },
        {
          headers: {
            "Session-Token": token,
          },
        },
      )
      if (response.status === 201) {
        const updatedData = response.data
        setConfigData((pre) => [
          ...pre,
          {
            ...updatedData,
          },
        ])
      }
    } catch (error) {
      console.error(error)
    }
  }

  const remove = async (change) => {
    const { configId } = change
    try {
      const response = await api.delete(`/feeder/relationships/${configId}/`, {
        headers: {
          "Session-Token": token,
        },
      })
      if (response.status === 204) {
        setConfigData((pre) => pre.filter((item) => item.id !== configId))
      }
    } catch (error) {
      console.log(error)
    }
  }

  const submitNewParentPageHandler = (newParentPage) => {
    save({
      newParentPage,
    })
  }

  const deleteParentPageHandler = (configId) => {
    remove({ configId })
  }

  //TODO: API save toggle status
  const toggleEnableHandler = (status) => {}

  return (
    <section className="container">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        // <ConfigHeader onEnableToggle={toggleEnableHandler} status={status} />
        <ConfigList
          configData={configData}
          onNewParentPageSubmit={submitNewParentPageHandler}
          onParentPageDelete={deleteParentPageHandler}
        />
      )}
    </section>
  )
}

export default ConfigPage
