import AnalyticsHeader from "./AnalyticsHeader"
import AnalyticsList from "./AnalyticsList"
import { useEffect, useState } from "react"
import { acb } from "../../../lib/axios"
import usePage from "../../../context/usePage"
import LoadingSpinner from "../../utilities/LoadingSpinner"
import { useNavigate, useSearchParams } from "react-router-dom"
import Pagination from "../../utilities/Pagination"

const AnalyticsPage = () => {
  const [analyticsData, setAnalyticsData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [postType, setPostType] = useState("all")
  const [timeBack, setTimeBack] = useState("")
  const [currentPage, setCurrentPage] = useState(0)
  const { selectedPage } = usePage()
  const navigate = useNavigate()
  const token = JSON.parse(localStorage.getItem("loggedInfo")).sessionToken
  const [searchParams] = useSearchParams()
  const pageSize = 10

  useEffect(() => {
    const fetchFeedData = async () => {
      setIsLoading(true)
      try {
        const response = await acb.get("postanalytics/posts", {
          params: {
            page_id: selectedPage.referenceId,
            post_type: postType,
            back: timeBack,
            order: searchParams.get("sm"),
            order_field: searchParams.get("sf") ?? "created_time",
            ps: pageSize, //amount
            p: currentPage, //displayPage
          },
          headers: {
            "Session-Token": token,
          },
        })
        const responseData = await response.data.data.posts
        // console.log("DATA", responseData)
        setAnalyticsData(responseData)
      } catch (error) {
        console.log(error)
        if (error?.response?.status === 401) {
          navigate("/login?expired=true", { replace: true })
        }
      } finally {
        setIsLoading(false)
      }
    }
    if (selectedPage.referenceId) {
      fetchFeedData()
    }
  }, [
    selectedPage.referenceId,
    postType,
    timeBack,
    searchParams,
    navigate,
    token,
    currentPage,
  ])

  const changeTypeHandler = (selectType) => {
    setPostType(selectType)
  }

  const changeTimeBackHandler = (time) => {
    setTimeBack(time)
  }

  return (
    <section className="container overflow_right">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <AnalyticsHeader
            onTypeChange={changeTypeHandler}
            onTimeBackChange={changeTimeBackHandler}
            postType={postType}
            timeBack={timeBack}
          />
          {analyticsData.length === 0 ? (
            <div className="no_data_msg">Didn't find any data.</div>
          ) : (
            <AnalyticsList analyticsData={analyticsData} />
          )}
          <Pagination
            currentPage={currentPage}
            onPageChange={setCurrentPage}
            noData={analyticsData.length === 0}
          />
        </>
      )}
    </section>
  )
}

export default AnalyticsPage
