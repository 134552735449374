import AnalyticsPost from "./AnalyticsPost"
import styles from "./AnalyticsList.module.scss"
import { useState } from "react"
import { BsArrowDown, BsArrowUp } from "react-icons/bs"
import { useSearchParams } from "react-router-dom"
import { useEffect } from "react"

const AnalyticsList = ({ analyticsData }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const defaultFilter = {
    target: searchParams.get("sf") ?? "",
    filterMethod: searchParams.get("sm")
      ? searchParams.get("sm") === "desc"
        ? 1
        : 2
      : 0,
  }
  const [filterState, setFilterState] = useState(defaultFilter)

  const changeFilterHandler = (data) => {
    if (filterState.target === data) {
      setFilterState((pre) => {
        const newFilterMethod = (pre.filterMethod + 1) % 3
        return {
          target: newFilterMethod ? pre.target : "",
          filterMethod: newFilterMethod,
        }
      })
    } else {
      setFilterState({ target: data, filterMethod: 1 })
    }
  }

  const TableHeading = ({ label, filter }) => (
    <th onClick={() => changeFilterHandler(filter)}>
      <div className={styles.heading_item}>
        <span>{label}</span>
        {filterState.target === filter &&
          (filterState.filterMethod === 1 ? (
            <BsArrowDown size={15} />
          ) : (
            <BsArrowUp size={15} />
          ))}
      </div>
    </th>
  )

  useEffect(() => {
    if (filterState.target) {
      setSearchParams({
        sf: filterState.target,
        sm: filterState.filterMethod === 1 ? "desc" : "asc",
      })
    } else {
      searchParams.delete("sf")
      searchParams.delete("sm")
      setSearchParams(searchParams)
    }
  }, [filterState, searchParams, setSearchParams])

  return (
    <div className={styles.table_container}>
      <table>
        <thead className="table_header">
          <tr>
            <th>Data</th>
            <TableHeading label="Impressions" filter="post_impressions" />
            <TableHeading label="Clicks" filter="clicks" />
            <TableHeading label="CTR" filter="ctr" />
            <TableHeading label="Engagement" filter="post_reactions_total" />
          </tr>
        </thead>
        <tbody>
          {analyticsData.map((post) => {
            return (
              <AnalyticsPost
                key={post.pid}
                analyticsData={post}
                orderedColumn={filterState.target}
              />
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default AnalyticsList
