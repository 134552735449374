import FeedPost from "./FeedPost"

const FeedList = ({ feedData }) => {
  return (
    <div>
      {feedData.map((post, index) => {
        const key = `${post.feed}|post_${index}`
        return <FeedPost key={key} feedData={post} />
      })}
    </div>
  )
}

export default FeedList
