import { useEffect, useState } from "react"
import QueueList from "./QueueList"
import { api } from "../../../lib/axios"
import usePage from "../../../context/usePage"
import LoadingSpinner from "../../utilities/LoadingSpinner"
import moment from "moment"

const QueuePage = () => {
  const [queueData, setQueueData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const token = JSON.parse(localStorage.getItem("loggedInfo")).sessionToken
  const { selectedPage } = usePage()

  useEffect(() => {
    const fetchQueueData = async () => {
      setIsLoading(true)
      try {
        const response = await api.get("/feeder/rewritten-posts/", {
          params: {
            status: "pending",
          },
          headers: {
            "Session-Token": token,
          },
        })
        const responseData = response.data.results
        setQueueData(responseData)
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false)
      }
    }
    fetchQueueData()
  }, [selectedPage, token])

  const save = async (change) => {
    const { postid, action, postContent, choosenImages, pageId } = change
    try {
      const response = await api.post(
        `/feeder/rewritten-posts/${postid}/${action}/`,
        {
          content: postContent,
          publish_page: pageId,
          choosen_images: choosenImages,
        },
        {
          headers: {
            "Session-Token": token,
          },
        },
      )
      if (response.status === 200) {
        setQueueData((pre) => pre.filter((item) => item.id !== postid))
      }
    } catch (error) {
      console.error(error)
    }
  }

  const postActionHandler = (
    postid,
    action,
    postContent,
    choosenImages,
    pageId = null,
  ) => {
    save({
      postid,
      action,
      postContent,
      choosenImages,
      pageId,
    })
  }

  return (
    <section className="container">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <QueueList posts={queueData} onPostAction={postActionHandler} />
      )}
    </section>
  )
}

export default QueuePage
