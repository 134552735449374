import styles from "./NewConfigComment.module.scss"
import { useState } from "react"

const NewConfigComment = ({
  onNewCommentClose,
  onNewCommentSubmit,
  displayNewComment,
}) => {
  const emptyComment = {
    code: "",
    text: "",
  }
  const [newComment, setNewComment] = useState(emptyComment)

  const submitCommitHandler = (e) => {
    e.preventDefault()
    onNewCommentSubmit(newComment)
    setNewComment(emptyComment)
  }

  return (
    <div className={`overlay ${displayNewComment ? styles.show : null}`}>
      <div
        className={
          displayNewComment
            ? [styles.wrapper, styles.wrapper_active].join(" ")
            : styles.wrapper
        }
      >
        {/* <div className={styles.logo}>
          <Logo />
        </div> */}
        <form className={styles.create_comment} onSubmit={submitCommitHandler}>
          <h2>Create a new comment</h2>
          <div className={styles.inputGroup}>
            <label htmlFor="title">Code</label>
            <input
              type="text"
              name=""
              id="title"
              value={newComment.code}
              onChange={(e) =>
                setNewComment((prev) => ({ ...prev, code: e.target.value }))
              }
              required
            />
          </div>
          <div className={styles.inputGroup}>
            <label htmlFor="content">Text</label>
            <textarea
              name=""
              id="content"
              cols="30"
              rows="5"
              value={newComment.text}
              onChange={(e) =>
                setNewComment((prev) => ({ ...prev, text: e.target.value }))
              }
              required
            ></textarea>
          </div>
          <div className={styles.btnGroup}>
            <button className="primary_btn" type="submit">
              Create
            </button>
            <button
              type="button"
              className="primary_outline_btn"
              onClick={onNewCommentClose}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default NewConfigComment
