import styles from "./QueuePost.module.scss"
import { useEffect, useState } from "react"
import { LuClipboardCheck, LuClipboardX } from "react-icons/lu"
import {
  HiMiniMagnifyingGlassPlus,
  HiMiniMagnifyingGlassMinus,
} from "react-icons/hi2"
import QueueChildPost from "./QueueChildPost"
import { TbReplaceFilled } from "react-icons/tb"
import noimage from "../../../images/noimage.jpg"
import { api } from "../../../lib/axios"
import usePage from "../../../context/usePage"
import Select, { components as selectComponents } from "react-select"

const getOriginalPostUrl = (post) => {
  switch (post.source.uri.method) {
    case "feed":
      return post.reference_id
    case "facebook":
      return `https://www.facebook.com/${post.reference_id}`
  }

  return null
}

const QueuePost = ({ post, onPostAction }) => {
  const [displayChildTable, setDisplayChildTable] = useState(false)
  const [selectedImgIds, setSelectedImgIds] = useState(
    post.images.filter((image) => image.is_choosen).map((image) => image.id),
  )
  const [displayImgGallery, setDisplayImgGallery] = useState(false)
  const [imagesList, setImagesList] = useState(post.images)
  const [isGallery, setIsGallery] = useState(selectedImgIds.length > 1)
  const [isImageUploading, setIsImageUploading] = useState(false)
  const choosenImage = selectedImgIds.length
    ? imagesList.find((img) => selectedImgIds.includes(img.id))
    : null

  const defaultImageUrl = choosenImage ? choosenImage.preview : noimage
  const [postContent, setPostContent] = useState(post.content)
  const [isShowMore, setShowMore] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const isMessageTooLong = postContent.length > 150
  const token = JSON.parse(localStorage.getItem("loggedInfo")).sessionToken
  const [isPageModalVisible, setIsPageModalVisible] = useState(false)
  const { allPages } = usePage()
  let lastUsedPageIds = JSON.parse(
    localStorage.getItem("feederLastUsedPageIds") || "[]",
  )
  const options = allPages.map((page) => ({
    value: page.id,
    label: page.name,
    icon: page.logo,
    recentlyUsed: lastUsedPageIds.includes(page.id),
  }))

  const toggleImage = (imageId) => {
    setSelectedImgIds((prev) => {
      if (isGallery) {
        if (prev.includes(imageId)) {
          return prev.filter((prevImg) => prevImg !== imageId)
        }
        return [...prev, imageId]
      }

      return [imageId]
    })
  }

  const toggleGallery = (isActive) => {
    setIsGallery(isActive)

    if (!isActive) {
      setSelectedImgIds((prev) => (prev.length > 0 ? [prev[0]] : prev))
    }
  }

  const handleImageUpload = async (e) => {
    setIsImageUploading(true)

    const formData = new FormData()

    formData.append("post", post.id)
    formData.append("content", e.target.files[0])

    try {
      const response = await api.post("/feeder/redrawn-image/", formData, {
        headers: {
          "Session-Token": token,
        },
      })
      if (response.status === 201) {
        setImagesList((prev) => [
          ...prev,
          {
            id: response.data.id,
            preview: response.data.content,
          },
        ])
        toggleImage(response.data.id)
      }
    } catch (error) {
    } finally {
      e.target.value = null
      setIsImageUploading(false)
    }
  }

  return (
    <>
      <tr className={`table_content ${styles.wrapper}`}>
        <td>
          <div className={styles.post_content}>
            <button
              htmlFor="expand"
              className={styles.expand_btn}
              onClick={() => setDisplayChildTable(!displayChildTable)}
            >
              {displayChildTable ? (
                <HiMiniMagnifyingGlassMinus
                  size={20}
                  className={styles.minus}
                />
              ) : (
                <HiMiniMagnifyingGlassPlus size={20} className={styles.plus} />
              )}
            </button>
            <button
              className={styles.img_wrapper}
              style={{ position: "relative" }}
              onClick={() => setDisplayImgGallery(!displayImgGallery)}
            >
              <img src={defaultImageUrl} alt="post-img" />
              <div className={styles.change_icon}>
                <TbReplaceFilled size={30} />
              </div>
              {selectedImgIds.length > 1 && (
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    padding: 10,
                    background: "rgba(0, 0, 0, 0.3)",
                    fontWeight: "bold",
                    color: "#fff",
                    borderTopLeftRadius: 9,
                  }}
                >
                  +{selectedImgIds.length - 1}
                </div>
              )}
            </button>
            <div className={styles.main_content}>
              <h3>{post.source.name}</h3>
              {isEditing ? (
                <textarea
                  value={postContent}
                  rows={20}
                  onChange={(e) => setPostContent(e.target.value)}
                  onBlur={() => setIsEditing(false)}
                  autoFocus
                  style={{ fontSize: 16 }}
                />
              ) : (
                <>
                  <div onClick={() => setIsEditing(true)}>
                    {(isShowMore || !isMessageTooLong
                      ? postContent
                      : postContent.slice(0, 150) + "… "
                    )
                      .split("\n")
                      .filter((str) => str)
                      .map((str, index) => (
                        <p key={index}>{str}</p>
                      ))}
                  </div>
                  {isMessageTooLong && (
                    <button
                      className="show_more"
                      onClick={() => setShowMore(!isShowMore)}
                    >
                      {isShowMore ? "Show less" : "Show more"}
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </td>
        <td>
          <div className={styles.action_btns}>
            <button
              value="approve"
              className={`primary_btn ${styles.approve}`}
              onClick={(e) => setIsPageModalVisible(true)}
            >
              <LuClipboardCheck size={22} />
            </button>
            {isPageModalVisible && (
              <PageSelector
                defaultPageId={lastUsedPageIds[0]}
                onCancel={() => setIsPageModalVisible(false)}
                onSelect={(pageId) => {
                  lastUsedPageIds = [
                    pageId,
                    ...lastUsedPageIds.filter((id) => id !== pageId),
                  ].slice(0, 10)
                  localStorage.setItem(
                    "feederLastUsedPageIds",
                    JSON.stringify(lastUsedPageIds),
                  )
                  onPostAction("approve", postContent, selectedImgIds, pageId)
                  setIsPageModalVisible(false)
                }}
                options={[
                  ...lastUsedPageIds.map((id) =>
                    options.find((option) => option.value === id),
                  ),
                  ...options.filter(
                    (option) => !lastUsedPageIds.includes(option.value),
                  ),
                ]}
              />
            )}

            <button
              value="reject"
              className={`primary_btn ${styles.disapprove}`}
              onClick={(e) =>
                onPostAction(e.target.value, postContent, selectedImgIds)
              }
            >
              <LuClipboardX size={22} />
            </button>
          </div>
        </td>
      </tr>
      <tr className={`${styles.gallery_wrapper}`}>
        <td colSpan={2}>
          <div
            className={`${styles.replace_images_wrapper} ${
              displayImgGallery ? styles.display : styles.hide
            }`}
            style={{ margin: 0, padding: "10px 15px 0" }}
          >
            <label
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: 12,
                textTransform: "uppercase",
                gap: 7,
              }}
            >
              <input
                type="checkbox"
                defaultChecked={isGallery}
                onChange={(e) => toggleGallery(e.target.checked)}
                style={{ widht: 25, height: 25 }}
              />
              Gallery
            </label>
          </div>

          <div
            className={`${styles.replace_images_wrapper} ${
              displayImgGallery ? styles.display : styles.hide
            }`}
          >
            <h2>
              <TbReplaceFilled size={20} />
            </h2>

            <div className={`${styles.replace_images}`}>
              {imagesList.map((img) => (
                <div style={{ position: "relative" }}>
                  <button key={img.id} onClick={() => toggleImage(img.id)}>
                    <img
                      src={img.preview}
                      alt={`replace_image_${img.id}`}
                      className={`${
                        selectedImgIds.includes(img.id) && styles.selected
                      }`}
                      loading="lazy"
                    />
                  </button>
                  <a
                    href={img.content}
                    download={img.content?.replace(/^.*[\\/]/, "")}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      position: "absolute",
                      bottom: 7,
                      right: 5,
                      padding: 10,
                      background: "rgba(0, 0, 0, 0.6)",
                      color: "white",
                      borderTopLeftRadius: 9,
                    }}
                  >
                    ▼
                  </a>
                </div>
              ))}
              <label
                key="add"
                style={{
                  width: 110,
                  height: 110,
                  border: "2px dashed #999",
                  fontSize: isImageUploading ? 12 : 24,
                  color: "#999",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {isImageUploading ? "Uploading…" : "+"}
                <input
                  type="file"
                  onChange={handleImageUpload}
                  accept="image/*"
                  disabled={isImageUploading}
                  style={{ display: "none" }}
                />
              </label>
            </div>
          </div>
        </td>
      </tr>
      <QueueChildPost
        displayChildTable={displayChildTable}
        originalPostContent={post.original_content}
        originalPostLink={getOriginalPostUrl(post)}
      />
    </>
  )
}

const { Option } = selectComponents
const IconOption = (props) => (
  <Option {...props}>
    <div
      style={{
        display: "flex",
        gap: 20,
        alignItems: "center",
      }}
    >
      <img src={props.data.icon} style={{ width: 36 }} alt={props.data.label} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          gap: 5,
        }}
      >
        <span>{props.data.label}</span>
        {props.data.recentlyUsed && (
          <span
            style={{
              fontSize: 10,
              textTransform: "uppercase",
              color: props.isSelected ? "white" : "gray",
            }}
          >
            Recently used
          </span>
        )}
      </div>
    </div>
  </Option>
)

const PageSelector = ({ defaultPageId, onCancel, onSelect, options }) => {
  const [pageId, setPageId] = useState(defaultPageId)

  return (
    <div
      style={{
        position: "fixed",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        zIndex: 99,
        background: "rgba(0, 0, 0, 0.2)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 30,
          width: 400,
          background: "#fff",
          borderRadius: 5,
          border: "10px solid #999",
          padding: 40,
        }}
      >
        <Select
          options={options}
          components={{ Option: IconOption }}
          onChange={(e) => setPageId(e.value)}
          defaultValue={
            pageId && options.find((option) => option.value === pageId)
          }
        />
        <div
          style={{
            display: "flex",
            gap: 30,
          }}
        >
          <button
            onClick={() => onCancel && onCancel()}
            style={{
              width: "100%",
              background: "#ddd",
              padding: "10px 20px",
              borderRadius: 5,
              cursor: "pointer",
              fontWeight: "bold",
            }}
          >
            Cancel
          </button>
          <button
            onClick={() => onSelect && onSelect(pageId)}
            style={{
              width: "100%",
              background: "#269400",
              padding: "10px 20px",
              borderRadius: 5,
              cursor: "pointer",
              fontWeight: "bold",
              color: "#fff",
            }}
          >
            Select
          </button>
        </div>
      </div>
    </div>
  )
}

export default QueuePost
