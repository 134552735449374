import styles from "./ConfigList.module.scss"
import ConfigComment from "./ConfigComment"
import { useState } from "react"
import NewConfigComment from "./NewConfigComment"
import { MdPlaylistAdd } from "react-icons/md"

const AutoCommentsList = ({ comments, onCommentCreate, onCommentDelete }) => {
  const [showNewComment, setShowNewComment] = useState(false)

  const openNewCommentHandler = () => {
    setShowNewComment(true)
  }

  const closeNewCommentHandler = (e) => {
    setShowNewComment(false)
  }

  const submitNewCommentHandler = (comment) => {
    setShowNewComment(false)
    onCommentCreate(comment)
  }
  return (
    <div>
      <table className={styles.config}>
        <thead className="table_header">
          <tr>
            <th>Code</th>
            <th>Comment</th>
            <th>
              <button
                onClick={openNewCommentHandler}
                className="primary_btn narrow new"
              >
                <span>New</span>
                <MdPlaylistAdd size={22} />
              </button>
              <NewConfigComment
                onNewCommentClose={closeNewCommentHandler}
                onNewCommentSubmit={submitNewCommentHandler}
                displayNewComment={showNewComment}
              />
              {/* <NewConfigComment
                onNewCommentOpen={openNewCommentHandler}
                close={closeNewCommentHandler}
                submit={submitNewCommentHandler}
                display={showNewComment}
              /> */}
            </th>
          </tr>
        </thead>
        <tbody>
          {comments.length === 0 ? (
            <tr>
              <td colSpan={3} className="no_data_msg">
                There are no comments set up yet.
              </td>
            </tr>
          ) : (
            comments.map((comment) => (
              <ConfigComment
                key={comment.code}
                comment={comment}
                onCommentDelete={onCommentDelete}
              />
            ))
          )}
        </tbody>
      </table>
    </div>
  )
}

export default AutoCommentsList
