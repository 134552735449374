import { Outlet, useNavigate, useParams } from "react-router-dom"
import Header from "../header/Header"
import { useEffect } from "react"
import { acb, api } from "../../lib/axios"
import usePage from "../../context/usePage"

async function fetchPageLogo(pageId) {
  try {
    const response = await acb.get(
      `https://graph.facebook.com/${pageId}/picture?type=square`,
      {
        responseType: "blob",
      },
    )
    return URL.createObjectURL(response.data)
  } catch {
    return null
  }
}

const RootPage = () => {
  const { setAllPages, setSelectedPage } = usePage()
  const navigate = useNavigate()
  const { toolPageId } = useParams()

  const token = JSON.parse(localStorage.getItem("loggedInfo")).sessionToken

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await api.get("facebook/pages", {
          headers: {
            "Session-Token": token,
          },
        })
        const responseData = response.data
        const pageData = await Promise.all(
          responseData.map(async (page) => {
            return {
              id: page.id,
              referenceId: page.reference_id,
              name: page.name,
              logo: await fetchPageLogo(page.reference_id),
            }
          }),
        )
        pageData.sort((a, b) => a.name.localeCompare(b.name))
        setAllPages(pageData)
        if (toolPageId) {
          const currentPage = pageData.find(
            (page) => page.referenceId === parseInt(toolPageId),
          )
          setSelectedPage(currentPage)
        }
      } catch (error) {
        console.log(error)
        if (error?.response?.status === 403) {
          navigate("/login?expired=true", { replace: true })
        }
      }
    }

    fetchItems()
  }, [token, navigate, setAllPages, setSelectedPage, toolPageId])

  return (
    <>
      <Header />
      <Outlet />
    </>
  )
}

export default RootPage
